import Slider from 'react-slick';

import './brands.scss';

const slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

const brands_array = [
  'Hoshizaki',
  'Bohn',
  'Copeland',
  'Imperial',
  'Baldor',
  'Emerson',
  'Carrier',
  'Danfoss',
  'Arctic Air',
  'Beverage-Air',
  'Bison',
  'Central Exclusive',
  'Continental',
  'Crown Verity',
  'Delfield',
  'Eagle Group',
  'Everest',
  'Fagor',
  'Hoshizaki',
  'Howard McCray',
  'Ikon',
  'Kool-It',
  'Summit',
  'Traulsen',
  'True',
  'Turbo Air',
  'Victory',
  'Manitowoc',
  'Scotsman',
  'Ice-O-Matic',
  'Follett',
  'Kratos',
  'Kratos Ice',
  'Central Exclusive',
  'Omcan',
  'Kold Draft',
];

const Brands = ({ brands }) => (
  <section className="brands">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Brands We Repair</h2>
      </div>

      <Slider className="brands__list" {...slider_settings}>
        {brands
          .sort((a, b) => {
            if (a > b) return -1;
            return 1;
          })
          .map((brand, index) => (
            <div key={index}>
              <img src={brand} alt="brand" />
            </div>
          ))}
      </Slider>

      <ul className="brands__array">
        {brands_array.map((el, index) => (
          <li key={index}>{el}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Brands;
