import { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Routes, Route, Navigate } from 'react-router-dom';
import useFetch from 'react-fetch-hook';

import PageTemplate from './pages/PageTemplate';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccessibilityStatement from './pages/AccessibilityStatement';
import TermsOfUse from './pages/TermsOfUse';
import PageNotFound from './pages/404';
import ThankYouPage from './pages/ThankYouPage';

import { Spinner } from './components';

const ADMIN_URL = 'https://admin.commercial-appliance-repair.com/wp-json/wp/v2';

const App = () => {
  const categories = useFetch(`${ADMIN_URL}/categories`);
  const pages = useFetch(`${ADMIN_URL}/posts`);
  const general_settings = useFetch(`${ADMIN_URL}/general_settings`);

  const [locations, setLocations] = useState();
  const [settings, setSettings] = useState();

  // Define locations
  useEffect(() => {
    if (categories.error || pages.error) return toast.error('Data loading error. Please reload the page!');

    if (!categories.isLoading && !pages.isLoading) {
      const locationsData = categories?.data.map((el) => ({
        phone: el.acf.phone_number,
        booking: el.acf.booking_link,
        areas: el.acf.service_area,
        slug: el.slug,
        title: el.name,
        pages: pages.data
          ?.map((page) => {
            if (page.categories.includes(el.id)) {
              const pageData = {
                title: page.title.rendered,
                acf: page.acf,
                content: page.content.rendered,
                slug: page.slug,
              };

              return pageData;
            }

            return false;
          })
          .filter((page) => page !== undefined),
      }));

      setLocations(locationsData);
    }

    if (!general_settings.isLoading) setSettings(general_settings.data[1].acf);
  }, [categories, pages, general_settings]);

  if (!locations || !settings) return <Spinner />;

  return (
    <Routes>
      {locations.map((location) =>
        location.pages.map((page) => (
          <Fragment>
            <Route
              path={`/${location.slug}/${page.slug}`}
              element={
                <PageTemplate
                  location={{
                    title: location.title,
                    slug: location.slug,
                  }}
                  data={page}
                  phone={location.phone}
                  title={page.title}
                  areas={location.areas}
                  booking={location.booking}
                  whyChooseUs={settings.why_choose_us}
                  services={settings.services}
                  reviews={settings.reviews}
                  brands={settings.brands}
                  social_media={settings.social_media}
                />
              }
            />
            <Route
              path={`/${location.slug}`}
              element={<Navigate replace to={`/${location.slug}/appliance-repair`} />}
            />
          </Fragment>
        )),
      )}

      <Route path="/" element={<Navigate replace to="/miami" />} />

      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />

      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/404" element={<PageNotFound />} />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

export default App;
