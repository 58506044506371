import { useEffect, useState } from 'react';
import './about.scss';

const About = ({ image, content }) => {
  const [title, setTitle] = useState();

  useEffect(() => {
    const location = window.location.pathname.split('/')[2];

    switch (location) {
      case 'dishwasher-repair':
        setTitle('Dishwasher Repair');
        break;
      case 'dryer-repair':
        setTitle('Dryer Repair');
        break;
      case 'freezer-repair':
        setTitle('Freezer Repair');
        break;
      case 'microwave-repair':
        setTitle('Microwave Repair');
        break;
      case 'oven-repair':
        setTitle('Oven Repair');
        break;
      case 'range-repair':
        setTitle('Range Repair');
        break;
      case 'refrigerator-repair':
        setTitle('Refrigerator Repair');
        break;
      case 'washer-repair':
        setTitle('Washer Repair');
        break;
      default:
        setTitle('Appliance Repair');
        break;
    }
  }, [content]);

  return (
    <section className="about">
      <div className="container">
        <div className="container__inner">
          <div className="about__content">
            <h2 className="section-title">Commercial {title} Experts</h2>

            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
          <div className="mobile-image">
            <img src={image} alt="About us" />
          </div>
          <div
            className="about__image"
            style={{
              backgroundImage: `url('${image}')`,
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default About;
