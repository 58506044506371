import './offers.scss';

const Offers = () => (
  <section className="offers">
    <div className="container">
      <div className="container__inner">
        <div className="offers__left">
          <p>Our Offers</p>

          <p>
            We Beat Any Offer!
            <br />
            No Hidden Fees!
          </p>
        </div>

        <div className="offers__right">
          <div>
            <p>
              <span>$0</span>Service Fee With Any Repair
            </p>
            <p>
              <span>$25</span>
              <span>Off</span> For Veterans and Seniors
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Offers;
