// import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import './services.scss';

const slider_settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Services = ({ services, location }) => (
  <section className="services">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Our Services</h2>
      </div>

      <Slider className="services__list" {...slider_settings}>
        {services
          .sort((a, b) => {
            if (a.link.post_title < b.link.post_title) return -1;
            return 1;
          })
          .map((service) => (
            <ServiceCard {...{ service, location, key: service.link.ID }} />
          ))}
      </Slider>

      <div className="ticker">
        <div className="ticker__text">
          <p>
            <span>12+</span> Years of Experience
          </p>
          <div className="circle" />
          <p>
            <span>3 000+</span> Satisfied Clients
          </p>
          <div className="circle" />
          <p>
            <span>20+</span> Professionals
          </p>
          <div className="circle" />
          <p>
            <span>99,6%</span> Positive Reviews
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Services;

const ServiceCard = ({ service }) => (
  <div className="services__list--card">
    <div className="icon">
      <img src={service.icon} alt={service.title} />
    </div>

    <h4>{service.link.post_title}</h4>

    <p>{service.description}</p>

    <img src="../assets/images/link_arrow.svg" alt="arrow" className="arrow" />
  </div>
);

// const ServiceCard = ({ service, location }) => (
//   <Link to={`/${location.slug}/${service.link.post_name}`} className="services__list--card">
//     <div className="icon">
//       <img src={service.icon} alt={service.title} />
//     </div>

//     <h4>{service.link.post_title}</h4>

//     <p>{service.description}</p>

//     <img src="../assets/images/link_arrow.svg" alt="arrow" className="arrow" />
//   </Link>
// );
