import { Link } from 'react-router-dom';

import './footer.scss';

const Footer = ({ location, phone, email }) => (
  <footer className="page-footer">
    <div className="container">
      <div className="container__inner">
        <Link to={`/${location ? location : ''}`} className="page-footer__logo">
          <img src="../assets/images/footer_logo.svg" alt="Logo" />
        </Link>

        {phone && (
          <a href={`tel:${phone}`} className="phone">
            {phone}
          </a>
        )}

        {/* <a href={`mailto:${email}`} className="email">
          {email}
        </a> */}

        <div className="links">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-of-use">Terms Of Use</Link>
          <Link to="/accessibility-statement">Accessibility Statement</Link>
        </div>

        <Underline />
      </div>
    </div>
  </footer>
);

export default Footer;

const Underline = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="page-footer__underline">
      <div className="container">
        <span>© {currentYear} SameDay Appliance Repair. All rights reserved.</span>

        <a href="https://toporin.com" target="_blank" rel="noReferrer">
          Designed by <strong>Toporin Studio®</strong> in California
        </a>
      </div>
    </div>
  );
};
