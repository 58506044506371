import './hero.scss';

const Hero = ({ title, image, booking, phone }) => (
  <section className="hero">
    <div className="container">
      <div className="container__inner">
        <div className="mobile-image">
          <img src={image} alt={title} />
        </div>

        <div className="hero__content">
          <h1>
            Same-Day
            <br />
            {title} Services
          </h1>
          <p>Your Local Professionals</p>

          <div className="hero__content--links">
            <a href={booking} className="red-button">
              Book A Service
            </a>
            <span>
              or call us <a href={`tel:${phone}`}>{phone}</a>
            </span>
          </div>
        </div>

        <div
          className="hero__image"
          style={{
            backgroundImage: `url('${image}')`,
          }}
        />
      </div>
    </div>
  </section>
);

export default Hero;
