import { Layout } from '../components';

import { Hero, Offers, WhyWe, About, Services, Reviews, Areas, Brands, CallToAction } from '../sections';

const PageTemplate = ({
  location,
  data,
  phone,
  title,
  areas,
  booking,
  whyChooseUs,
  services,
  reviews,
  brands,
  social_media,
}) => (
  <Layout
    pageId={data.slug}
    phone={phone}
    booking={booking}
    location={location.title}
    slug={location.slug}
    pageTitle={title}
    social_media={social_media}
  >
    <Hero title={title} image={data.acf.main_image} phone={phone} booking={booking} />
    <Offers />
    <WhyWe data={whyChooseUs} />
    <About image={data.acf.about_image} content={data.content} />
    <Services services={services} location={location} />
    <Reviews reviews={reviews} />
    {areas && <Areas areas={areas} />}
    <Brands brands={brands} />
    <CallToAction phone={phone} booking={booking} />
  </Layout>
);

export default PageTemplate;
