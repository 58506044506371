import './whyWe.scss';

const WhyWe = ({ data }) => (
  <section className="why-we">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Why Same-Day Appliance Repair?</h2>

        <div className="why-we__grid">
          {data.map((el, index) => (
            <div key={index}>
              <div className="icon">
                <img src={el.icon} alt={el.title} />
              </div>

              <h4>{el.title}</h4>

              <p>{el.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default WhyWe;
