import './cta.scss';

const CallToAction = ({ booking, phone }) => (
  <section className="call-to-action">
    <div className="container">
      <div className="container__inner">
        <h2 className="section-title">Looking for professional help?</h2>

        <div className="links">
          <a href={booking} rel="noReferrer" target="_blank" className="red-button">
            Book A Service
          </a>

          <span>
            or call us <a href={`tel:${phone}`}>{phone}</a>
          </span>
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;
