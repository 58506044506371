import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// import { SocialIcons } from '../';

import './header.scss';

const Header = ({
  phone,
  booking,
  location,
  // social_media
}) => {
  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    setShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="container">
        <div className="mobile-btn">
          <a href={`tel:${phone}`} className="page-header__phone">
            {phone}
          </a>
        </div>
        <div className="container__inner">
          {/* <SocialIcons social_media={social_media} /> */}
          <Link to={`/${location ? location : ''}`} className="page-header__logo">
            <img src="../assets/images/header_logo.svg" alt="Logo" />
          </Link>

          {phone && booking && (
            <div className="page-header__links">
              <a href={`tel:${phone}`} className="page-header__phone">
                {phone}
              </a>
              <a href={booking} rel="noReferrer" target="_blank" className="red-button">
                Book Now
              </a>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
